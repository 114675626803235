<template>
  <div class="relative" :class="[`w-${size}`]">
    <div>
      <img v-if="skin" :src="skin.imageURL" :class="[`w-${size}`]">
      <img v-else src="/public/counter-page/member-5.png" :class="[`w-${size}`]">
    </div>
    <div v-for="(drink, index) in drinks" :key="index" 
      class="absolute z-20"
      :class="[`w-${Math.floor(size/2)}`]"
      :style="{ 
        right: `${size === 16 ? -7 : -10}px`,
        bottom: `${(size === 16 ? 4 : 6) + getTotalHeight(drinks.slice(index + 1)) + (drink.name === 'Rainbow Rock' ? size === 16 ? 3 : 4 : 0)}px`
      }"
      @mouseenter="hoveringGlassIndex = index" @mouseleave="hoveringGlassIndex = null" 
    >
      <div v-if="showDrinkDescription && hoveringGlassIndex === index" class="absolute w-64 -left-28 flex bottom-10 z-30">
        <div class="relative w-full flex justify-center">
          <img src="/public/counter-page/drink-bubble.png" class="w-full">
          <div class="absolute h-full p-5">
            <div v-if="userDetail.isBartender" class="flex flex-col items-center">
              <p>
                <span class="">{{ userDetail.nickName }}</span>
                เป็นบาร์เทนเดอร์ คอยชวนคุย รับฟัง ดูแลสารทุกข์สุขดิบคนในบาร์
              </p>
            </div>
            <div v-else-if="shouldShowSpecialDrink(userDetail)">
              <p>
                <span class="">{{ userDetail.nickName }}</span> กำลังดื่ม {{ drink.name }} ที่มีคนเลี้ยง
                โดยแก้วพิเศษจะอยู่ได้ 3 วัน
              </p>
            </div>
            <div v-else class="flex flex-col items-center">
              <p>
                <span class="">{{ userDetail.nickName }}</span> กำลังดื่ม {{ drinkById(userDetail.drinkId)?.name }} {{
                  drinkById(userDetail.drinkId)?.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        :src="drink.imageURL"
      />
    </div>
    <img v-if="hat" :src="hat.imageURL" class="hat" :style="sizeStyles.hat">
    <img v-if="face" :src="face.imageURL" class="face" :style="sizeStyles.face">
    <img v-if="shirt && !skin" :src="shirt.imageURL" class="shirt" :style="sizeStyles.shirt">
    <img v-if="necklace" :src="necklace.imageURL" class="necklace" :style="sizeStyles.necklace">
  </div>
</template>

<script>
import global from '~/mixins/global';
import sumBy from 'lodash/sumBy';

export default {
  mixins: [global],
  props: {
    showDrinkDescription: {
      type: Boolean,
      default: true,
    },
    userDetail: {
      type: Object,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      default: 1,
    },
    isBartender: {
      type: Boolean,
      default: false,
    },
    drinkId: {
      type: Number,
      required: false,
    },
    size: {
      type: Number,
      default: 24,
      validator: (value) => [16, 24].includes(value) // restrict to supported sizes
    },
  },
  methods: {
    getTotalHeight (drinks) {
      const sum = sumBy(drinks, 'height');
      return sum;
    },
    drinkById (id) {
      return this.$store.drinks.find(drink => drink.id === id);
    },
  },
  computed: {
    loggedIn () {
      return this.userDetail && this.userDetail.authId;
    },
    hat () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'hat').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'hat').pop().accessory : null;
    },
    skin () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'skin').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'skin').pop().accessory : null;
    },
    necklace () {
      return this.loggedIn && this.isBartender ? { imageURL: 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/public-web-app/images/bartender/ribbon.png' } : this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'necklace').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'necklace').pop().accessory : null;
    },
    shirt () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'shirt').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'shirt').pop().accessory : null;
    },
    face () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'face').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'face').pop().accessory : null;
    },
    drinks () {
      if (this.loggedIn && this.shouldShowSpecialDrink(this.userDetail)) {
        return this.getSpecialDrinks(this.userDetail);
      }
      return this.isBartender ? [{ imageURL: 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/public-web-app/images/bartender/shaker.png' }] : [{ imageURL: this.drinkById(this.drinkId)?.imageURL }];
    },
    sizeStyles() {
      const isSmall = this.size === 16
      return {
        hat: {
          width: isSmall ? '2.3rem' : '3.5rem',
          top: isSmall ? '-7px' : '-10px',
          left: isSmall ? '7px' : '10px'
        },
        face: {
          width: isSmall ? '2.75rem' : '4rem',
          top: isSmall ? '6px' : '9px',
          left: isSmall ? '5px' : '8px'
        },
        shirt: {
          width: isSmall ? '3.5rem' : '5rem',
          top: isSmall ? '20px' : '30px'
        },
        necklace: {
          width: isSmall ? '3.5rem' : '5rem',
          top: isSmall ? '20px' : '30px'
        }
      }
    }
  },
  data () {
    return {
      hoveringGlassIndex: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.hat {
  position: absolute;
}

.face {
  @apply absolute;
  z-index: 15;
}

.necklace {
  @apply absolute;
}

.shirt {
  @apply absolute;
}
</style>
